<div class="content" role="main" style=background-color:rgb(251,245,255)>
  <!-- <demo-map >Demo-Map1 here</demo-map> -->
  <stage-comp [width]=1600 [height]=980> StageComponent here</stage-comp>
  <div style="position: absolute; top: 5px; left: 50; z-index: 9999">
    <a href="https://docs.google.com/document/d/1IKwKtXxFkKQIL9FXchZ0zqhZvCisjLi7qsD-JdlEqAg/view#" target="_blank" rel="noopener">{{ title }}</a>
    <br/>
    <span>{{ timestamp }}</span>
    <br/>
    <button type="submit" id="fsOpenFileButton" onclick="showOpenFilePicker()">OpenFile</button>
    <br/>
    Game: <span id="group_name">----</span>
  </div>
  <div>
   
  </div>
 </div>